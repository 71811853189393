html {
  background-color: #1d2026;
  max-height: 100vh;
}

.App {
  text-align: center;
  height: 100%;
  min-height: 80vh;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: white;
  background-color: #1d2026;
}

.Glasses {
  width: 100%;
  padding: 10%;
  max-width: 500px;
}

.Response {
  padding: 2% 2%;
  max-width: 500px;
}

.Chat {
  width: 100%;
  max-width: 500px;
  padding: 10px;
}
.Message-Input {
  background-color: #282d35;
  border: none;
  width: 100%;
  padding: auto;
  color: white;
}

.Send-Message {
  width: 100%;
  margin-top: 10px;
}
